'use client';

import { cx } from '@emotion/css';
import { cloneElement, forwardRef, Fragment, type ComponentProps, type ReactElement, type ReactNode } from 'react';

import { Loader } from '../Loader';
import type { Color, Size, Variant } from '../types';

type Animation = 'ripple' | 'none';

export type ButtonProps = {
  animation?: Animation;
  buttonStyle?: Color | 'primary';
  buttonVariant?: Variant;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  fab?: boolean;
  icon?: ReactElement;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  iconSize?: Size;
  label?: ReactNode;
  loading?: boolean;
  size?: Size;
  truncate?: boolean;
  type?: string;
} & Partial<ComponentProps<'button'>>;

const shouldShowAnimation = (props: ButtonProps) => {
  return !(props.loading || props.disabled) || props.animation === 'none';
};

const getLoaderColor = (variant?: Variant, color?: Color) => {
  if (color) {
    switch (color) {
      case 'primary':
        if (variant === 'raised' || variant === 'dark') {
          return 'neutral-100';
        } else return 'primary-500';
      case 'secondary':
        if (variant === 'dark') {
          return 'neutral-100';
        } else return 'secondary-900';
      case 'success':
        return 'success-700';
      case 'danger':
        return 'danger-700';
      case 'warning':
        return 'warning-700';
      default:
        return 'secondary-900';
    }
  } else return 'secondary-900';
};

const defaultProps = {
  animation: 'ripple',
  iconSize: 'sm',
  disabled: false,
  loading: false,
  fab: false,
  truncate: true,
};

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    animation,
    buttonStyle,
    buttonVariant,
    children,
    className,
    disabled,
    fab,
    icon,
    iconLeft,
    iconRight,
    iconSize,
    label,
    loading,
    size,
    truncate,
    onClick = () => {},
    type,
    ...restProps
  } = { ...defaultProps, ...props };

  return (
    <button
      ref={ref}
      className={cx(
        buttonStyle ? `btn-${buttonStyle}` : null,
        size ? `btn-${size}` : null,
        buttonVariant ? `btn-${buttonVariant}` : null,
        shouldShowAnimation(props) ? `btn-${animation}` : null,
        { 'btn-icon-left': !!iconLeft },
        { 'btn-icon-right': !!iconRight },
        !iconLeft && !iconRight ? 'layout-align-center-center' : 'layout-align-start-center',
        { 'bg-secondary-200': disabled && buttonVariant !== 'default' },
        { loading: loading },
        { 'btn-fab': fab },
        { 'text-truncate': truncate },
        'btn display-inline-block medium',
        className,
      )}
      disabled={disabled}
      onClick={(e) => (!(loading || disabled) ? onClick(e) : undefined)}
      type={type ? type : 'button'}
      {...restProps}>
      <span
        className={cx(
          !iconLeft && !iconRight ? 'layout-align-center-center' : 'layout-align-start-center',
          'layout-row',
        )}>
        {!children && (
          <Fragment>
            {loading ? <Loader isPageLoader={false} color={getLoaderColor(buttonVariant, buttonStyle)} /> : null}

            {icon && !label
              ? cloneElement(icon, {
                  className: cx(iconSize ? `btn-icon-${iconSize}` : null),
                })
              : null}

            {iconLeft
              ? cloneElement(iconLeft, {
                  className: cx(iconSize ? `btn-icon-${iconSize}` : null, 'btn-icon-left'),
                })
              : null}

            {label && !icon ? (
              <span className={cx(truncate ? 'text-truncate' : '', 'btn-text flex')}>{label}</span>
            ) : null}

            {iconRight
              ? cloneElement(iconRight, {
                  className: cx(iconSize ? `btn-icon-${iconSize}` : null, 'btn-icon-right'),
                })
              : null}
          </Fragment>
        )}
        {children}
      </span>
    </button>
  );
});
